import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './mycurrency.module.css';
// components
import Team from '../components/team';
import Resume from '../components/resume';
import Layout from '../components/item/layout';
import GetConsultation from 'components/free-consultation';
// other
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';
import Blockquote from '../components/blockquote';

function LadderOutCase() {
  const data = useStaticQuery(graphql`
    query {
      mcReview: file(relativePath: { eq: "mc-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "mycurrency-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "mycurrency-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "mycurrency-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "mycurrency-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "mycurrency-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "mycurrency-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "mycurrency-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      score={['E-commerce', 'Canada', 'May, 2019', 'Finished']}
      navBtn="#14181A"
      layoutClassName={styles.main}
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="MyCurrency&nbsp;— loyalty points exchange platform"
      subTitle="How Brocoders helped to build a comprehensive ecosystem of goods and services exchange based on the micro-currency concept"
      metaData={meta.mycurrency}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          "MyCurrency seeks to let every single individual on Earth make money, literally. Each
          person can issue their own currency and use it in commercial exchanges. No longer will
          lack of cash be a limit on someone's ability to procure what they need. Now they can
          directly barter a representation of their goods/services, in the form of a personal
          currency, for what others provide."
        </p>
        <div className={styles.author}>
          <p>
            <b>Amin Khadempour</b>
          </p>
          <p>CEO at MyCurrency</p>
        </div>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          Amin Khadempour is an individual entrepreneur from Canada, Vancouver. Based on multiple
          scientific research studies of the economics sector, he decided to launch an ambitious
          startup in order to create an international commercial exchange community.
        </p>
        <p className={itemStyles.paragraph}>
          Since 2018, Brocoders has built fruitful and trusted business relationships with Amin K.
          We have managed to combine our skills and knowledge to develop a revolutionary
          first-of-its-kind universal Loyalty Points exchange platform for businesses and their
          end-customers.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          It's quite a challenge for the small-sized business to expand its sales channels. It's
          also not always possible for the growing companies to attract new partners or run customer
          loyalty points programs on a regular basis as of limited resources.
        </p>
        <p className={itemStyles.paragraph}>
          A bunch of small businesses who want to provide their clients with a loyalty points
          program, tend to use old-hat methods like stamp cards. Companies have to stamp a new card
          each time a customer makes a purchase, and then award their customer with a free product
          or service once the stamp card has all of its 'slots' stamped. Unfortunately, it might
          take thousands or even millions of dollars to end up with such a system.
        </p>
        <p className={itemStyles.paragraph}>
          Driven by his great passion to reshape the business world and help fast-growing companies
          boost their sales, Amin K. decided to develop a robust solution that could make that dream
          come true. To analyze the first steps and investigate the market, Amin opted for
          scientific-based research approaches that helped him to acquire a bigger picture of what
          must be done.
        </p>
        <p className={itemStyles.paragraph}>
          Amin managed to create the backend with Ruby on Rails, so he needed a frontend dedicated
          team to take care of the rest. He was looking for self-motivated experts that would
          embrace the technical deep dive practices to cover all the bases and pitfalls.
        </p>
        <p className={itemStyles.paragraph}>
          After a long search, Amin reached out to the Brocoders team to check if our expertise can
          fit his project and idea. Later on, it turned out that we share the same business mindset,
          so we came up with a burning desire to carry out the project to its full extent.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>
        <p className={itemStyles.paragraph}>
          It was vital to carefully go through all the details and estimate the project's potential
          risks, so we began with conference calls in the first place. The client shared his vision
          of the final product and demonstrated the API that he had written before so we could align
          our further development stages.
        </p>
        <p className={itemStyles.paragraph}>
          Amin had a basic understanding of the concept, but he lacked expertise in the MPV field.
          That said, Brocoders leveraged its multi-year MVP development experience to propose the
          optimal strategy, test the project's viability and highlight the key features while taking
          into account the client's feedback.
        </p>
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          After requirements elaboration, the Brocoders team opted for the Scrum model to ensure the
          client could keep track of every activity and change. The choice of the Scrum model along
          with the iterative development methodology allowed for data transparency, as well as made
          it possible for us to seamlessly introduce functionality changes along the way.
        </p>
        <p className={itemStyles.paragraph}>
          To determine how data can be shown, stored, created or changed, we wrote a business logic,
          on the basis of which the designs in Figma were created. We worked shoulder to shoulder
          with our partner and managed to combine our forces to enable the flexibility of the entire
          development process.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Needless to say, the client needed a digital business partner capable of designing a 100%
          reliable and user-centric infrastructure while sticking to the established budget. Last
          but not least, the product had to be built under tight timeframes, since Amin needed a
          fully-functioning solution within the shortest possible period.
        </p>
        <p className={itemStyles.paragraph}>
          In light of this, we utilized React Native to create a cross-platform product, thus
          significantly reducing the development time and costs. Moreover, such an open-source
          mobile application framework allowed for fast and smooth features implementing and
          updating for both Android and iOS at the same time.
        </p>
      </div>
      <div className={itemStyles.container}>
        <GetConsultation
          title="Schedule a call with our experts to get a free consultation"
          text="Are you interested in Loyalty Program Solution?"
          linkText="Get free consultation"
          linkId={identificators.GET_A_FREE_CONSULTATION_BANNER}
        />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          Brocoders embraced the best possible development practices based on weekly communications
          and reports, along with a clear assignment of responsibilities, in order to ensure our
          specialists could effectively tackle all the challenging tasks.
        </p>
        <p className={itemStyles.paragraph}>
          We assembled a team of the following in-house seasoned experts:
        </p>
        <Team
          color="#2991F7"
          list={[
            { title: 'Frontend\ndevelopers', count: 2 },
            { title: 'Business\nanalyst', count: 1 },
            { title: 'Project\nmanager', count: 1 },
            { title: 'UI/UX\ndesigner', count: 1 },
            { title: 'QA\nengineer', count: 1 },
          ]}
        />
        <Resume
          services={['product', 'mobileDev']}
          technology={['reactNative', 'nodejs', 'figma']}
        />
      </div>
      <Blockquote
        img={data.mcReview.childImageSharp.fluid}
        text="“Their professionalism, experience, and focus on delivery are excellent.”"
        downloadReviewLink="https://bit.ly/3EDtCLB"
        isRating
        author={
          <React.Fragment>
            <b>Amin Khadempour</b>
            <br />
            CEO at MyCurrency
          </React.Fragment>
        }
      />
      <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screen4} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business Challenges</h2>
        <p className={itemStyles.paragraph}>
          From the very beginning of the project, we managed to establish a dynamic working
          environment with Amin Khadempour. Despite the different time zones, we had been resolving
          all emerging issues on a hassle-free basis during the entire development process.
          Nevertheless, handling all parts of such unique and large-scale projects always comes
          along with multiple formidable tasks.
        </p>
        <p className={itemStyles.paragraph}>
          That said, to strengthen the final product’s market power, as well as to cover a wider
          audience, we decided to integrate the Craigslist’s API into our solution. Early on, the
          API’s architecture posed an additional challenge for the Brocoders team when it came to
          the integration phase.
        </p>
        <p className={itemStyles.paragraph}>
          Thankfully, our experts had set up a transparent development process that let them
          adequately collaborate with other specialists and find a workaround within a tight
          schedule.
        </p>
        <p className={itemStyles.paragraph}>
          Given the Craigslist traction in the US and Canada, it was an excellent service to opt
          for. As a result, when a user posts his goods in the MyCurrency app, the announcements
          will automatically appear on the{' '}
          <a className={styles.link} href="https://www.craigslist.org" target="__black">
            www.craigslist.org
          </a>{' '}
          website.
        </p>
      </div>
      <Img fluid={data.screen5.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered Solution</h2>
        <p className={itemStyles.paragraph}>
          Backed by a well-knit collaboration with the client, the Brocoders team delivered an
          innovative multi-functioning MVP ready to be tested by end-users.
        </p>
        <p className={itemStyles.paragraph}>
          The client-oriented platform enables users to create their own micro-currencies and
          participate in various loyalty points programs, as well as trade with other users using
          their smartphones. Meanwhile, businesses can profit from expanding their distribution
          channels, thus attracting new potential customers.
        </p>
        <p className={itemStyles.paragraph}>
          With MyCurrency on board, there is a superior advantage over the traditional loyalty
          points programs for small businesses: a mobile-based solution lets companies immediately
          launch their own loyalty points program for free with just a click of a button.
        </p>
      </div>
      <Img fluid={data.screen6.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          As a business owner, all you have to do is notify your customers about your discounts on
          the app and provide them with your shop's loyalty points. You can then scan the QR Code
          from the users' smartphones to redeem their loyalty points and award customers with your
          services or goods right after.
        </p>
        <p className={itemStyles.paragraph}>
          In fact, such a system completely avoids the expenses of printing or buying stamps.
          Besides, you no longer have to ask customers to fill out forms. Customers also don't need
          to worry about losing their cards. As long as they keep their smartphones close at hand
          with the MyCurrency app installed, their loyalty points are always with them as well.
        </p>
        <p className={itemStyles.paragraph}>
          On top of that, here are the key features that all users can benefit from when using the
          MyCurrency app:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            You can create your own currency and issue as many units as you wish. Each currency type
            is related to the particular goods or services that you provide.
          </li>
          <li>
            You can create stores for your currencies, add your products or services to the store
            and set prices so that other users could buy your products or services, or accept your
            direct offers as well.
          </li>
          <li>
            You can add any type of goods or services that you can theoretically provide, whether
            that be apples, oranges, auto service, watch repair service, to name just a few.
          </li>
          <li>
            Any user that acquired your currency can spend it to purchase the goods or services at
            the stores related to that particular currency.
          </li>
          <li>
            You can make a trading request to other users in order to exchange your currencies for
            theirs. For example, you can offer a user to exchange his Python training courses for
            your sushi. When you both come to an agreement, the exchange occurs automatically.
          </li>
          <li>
            You can also use the currencies that you might receive from other users. So even if a
            store doesn't provide any goods or services that you require at the moment, you can
            still find further use for it. You can utilize it as an item of trade that allows you to
            acquire other currencies from other stores that you do require.
          </li>
          <li>
            You can offer to sell, swap or buy loyalty points on Craigslist, share the link to the
            Craigslist post on the MyCurrency app so that the Craigslist post will automatically be
            shown to all MyCurrency users.
          </li>
        </ul>
      </div>
      <Img fluid={data.screen7.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={clsx(itemStyles.lastItem, itemStyles.inner)}>
        <h2 className={itemStyles.title}>Future plans</h2>
        <p className={itemStyles.paragraph}>
          To date, we're just getting started, and our work is underway to integrate additional
          innovative features based on the latest cutting-edge tech trends. Further development
          phases will also include optimization enhancements to enable Amin Khadempour to keep
          expanding the platform's reach.
        </p>
      </div>
    </Layout>
  );
}

export default LadderOutCase;
